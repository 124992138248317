<template>
  <list url="/M/Server/GetMoreFans" :showLoading="true" :show="true">
    <template v-slot:default="data">
      <follow :follow="{...data,type:'unsubscribe'}"></follow>
    </template>
  </list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import list from "@/components/common/list.vue";
import Follow from "@/components/follow/Follow.vue";
export default defineComponent({
  name: "Attention",
  components: {
    list,
    Follow,
  },
});
</script>